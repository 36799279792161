import {
  OTHERS,
  PAYMENT_METHODS,
  PRODUCTS,
  ServiceOptionEnum,
} from "../../components/PaymentMethodCheckList/enums";
import { ICheckboxItem } from "../../components/PaymentMethodCheckList/interfaces";
import { defaultTo, get } from "lodash";
import { ProcessorPaymentMethod } from "../../../types/processor_payment_method";

export const getCheckBoxItem = (
  value: ServiceOptionEnum,
  labelList: Record<string, string>
): ICheckboxItem => {
  return {
    label: labelList[value],
    value: value,
  };
};

export const getAvailableProducts = (
  smartlinksActive: boolean,
  vposActive: boolean,
  subscritionsActive: boolean
) => {
  const product: ICheckboxItem[] = [];

  if (smartlinksActive)
    product.push(getCheckBoxItem(ServiceOptionEnum.WEBCHECKOUT, PRODUCTS));
  if (vposActive)
    product.push(getCheckBoxItem(ServiceOptionEnum.VPOS, PRODUCTS));
  if (subscritionsActive)
    product.push(getCheckBoxItem(ServiceOptionEnum.SUBSCRIPTIONS, PRODUCTS));

  return product;
};

export const processorIsPaymentMethod = (
  processors: ProcessorPaymentMethod[],
  service: ServiceOptionEnum
) => {
  return (
    processors.filter(
      (processor: ProcessorPaymentMethod) =>
        get(processor, "paymentMethod", "") === service
    ).length > 0
  );
};

export const getServiceOptions = (
  processors: ProcessorPaymentMethod[],
  services: ServiceOptionEnum[],
  labelList: Record<string, string>
): ICheckboxItem[] => {
  const availableServices = services.filter((service: ServiceOptionEnum) =>
    processorIsPaymentMethod(processors, service)
  );

  return availableServices.map((service: ServiceOptionEnum) =>
    getCheckBoxItem(service, labelList)
  );
};

export const getAvailableServices = (
  processors: ProcessorPaymentMethod[]
): {
  additionalOpt: ICheckboxItem[];
  merchantOpt: string[];
  payinOpt: ICheckboxItem[];
  payoutOpt: ICheckboxItem[];
} => {
  const processorsList = defaultTo(processors, []);
  const payinOpt: ICheckboxItem[] = getServiceOptions(
    processorsList,
    [
      ServiceOptionEnum.CARD,
      ServiceOptionEnum.CASH,
      ServiceOptionEnum.TRANSFER,
      ServiceOptionEnum.TRANSFER_SUBSCRIPTIONS,
    ],
    PAYMENT_METHODS
  );
  const payoutOpt: ICheckboxItem[] = getServiceOptions(
    processorsList,
    [
      ServiceOptionEnum.PAYOUTS_CASH,
      ServiceOptionEnum.PAYOUTS_TRANSFER,
      ServiceOptionEnum.PAYOUTS_CARD,
    ],
    PAYMENT_METHODS
  );
  const additionalOpt: ICheckboxItem[] = [];

  if (
    payinOpt.find(({ value }) => value === "card" || value === "subscriptions")
  ) {
    additionalOpt.push(getCheckBoxItem(ServiceOptionEnum.CHARGEBACK, OTHERS));
    additionalOpt.push(
      getCheckBoxItem(ServiceOptionEnum.CHARGEBACK_VOID, OTHERS)
    );
  }

  const merchantOpt: string[] = [
    ...payinOpt,
    ...payoutOpt,
    ...additionalOpt,
  ].map(({ value }) => value as string);

  return {
    additionalOpt,
    merchantOpt,
    payinOpt,
    payoutOpt,
  };
};
