import { NotificationContainerStyles as styles } from "../../containers/NotificationsContainer/NotificationContainer.styles";
import InputAdornment from "@mui/material/InputAdornment";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import { IconButton, TextField } from "@mui/material";
import React, { FC } from "react";
import { ISearchText } from "./SearchContext.interfaces";
import { useSearchTextState } from "./state/useSearchTextState";

const SearchText: FC<ISearchText> = (props: ISearchText) => {
  const { handleKeyPress, handleSearchChange } = useSearchTextState(props);

  return (
    <TextField
      placeholder={props.placeholder}
      sx={styles.searchText}
      onKeyDown={handleKeyPress}
      type="text"
      onChange={handleSearchChange}
      value={props.trxIdentifierFilter.trxIdentifier}
      inputProps={{ maxLength: 50 }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              data-testid="idIconButton"
              onClick={props.trxIdentifierFilter.handleSearchTrxIdentifier}
              edge="end"
              disabled={props.trxIdentifierFilter.trxIdentifier.length === 0}
            >
              <SellOutlinedIcon color={"primary"} />{" "}
            </IconButton>
          </InputAdornment>
        ),
      }}
      size="medium"
    />
  );
};

export default SearchText;
