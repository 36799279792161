import { OptionsEnum } from "../../shared/enums/OptionsEnum";
import { WebhookEventsEnum } from "./enums";
import { EventList } from "./interfaces";
import { WebhookService } from "../../shared/constants/enums/WebhookServiceEnum";

export const TRANSACTION_STATUS: Record<WebhookEventsEnum, string> = {
  [WebhookEventsEnum.approvedSubscription]: "Suscripción aprobada",
  [WebhookEventsEnum.approvedTransaction]: "Transacción aprobada",
  [WebhookEventsEnum.cashPreathorization]: "Preautorización",
  [WebhookEventsEnum.chargeback]: "Contracargos",
  [WebhookEventsEnum.initialized]: "Inicializado",
  [WebhookEventsEnum.review]: "En revisión",
  [WebhookEventsEnum.pending]: "Documentación enviada",
  [WebhookEventsEnum.approval]: "Ganado",
  [WebhookEventsEnum.declined]: "Perdido",
  [WebhookEventsEnum.failed]: "Fallido",
  [WebhookEventsEnum.expired]: "Vencido",
  [WebhookEventsEnum.declinedSubscription]: "Suscripción declinada",
  [WebhookEventsEnum.declinedTransaction]: "Transacción declinada",
  [WebhookEventsEnum.failedRetry]: "Intento fallido",
  [WebhookEventsEnum.lastRetry]: "Último intento",
  [WebhookEventsEnum.subscriptionApproved]: "Suscripción aprobada",
  [WebhookEventsEnum.subscriptionDeclined]: "Suscripción declinada",
  [WebhookEventsEnum.subscriptionDelete]: "Suscripción borrada",
};

export const PAYMENT_METHODS_EVENTS: Record<
  WebhookService,
  { [key: string]: string }
> = {
  card: {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
  "card-chargeback": {
    approval: TRANSACTION_STATUS.approval,
    declined: TRANSACTION_STATUS.declined,
    expired: TRANSACTION_STATUS.expired,
    failed: TRANSACTION_STATUS.failed,
    initialized: TRANSACTION_STATUS.initialized,
    pending: TRANSACTION_STATUS.pending,
    review: TRANSACTION_STATUS.review,
  },
  "card-chargeback-void": {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
  cash: {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    cashPreathorization: TRANSACTION_STATUS.cashPreathorization,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
  "payouts-card": {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
  "payouts-cash": {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
  "payouts-transfer": {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
  subscriptions: {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
    failedRetry: TRANSACTION_STATUS.failedRetry,
    lastRetry: TRANSACTION_STATUS.lastRetry,
    subscriptionApproved: TRANSACTION_STATUS.subscriptionApproved,
    subscriptionDeclined: TRANSACTION_STATUS.subscriptionDeclined,
    subscriptionDelete: TRANSACTION_STATUS.subscriptionDelete,
  },
  transfer: {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
  "transfer-subscriptions": {
    approvedSubscription: TRANSACTION_STATUS.approvedSubscription,
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedSubscription: TRANSACTION_STATUS.declinedSubscription,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
  vpos: {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
  webcheckout: {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
};

export const PAYIN_OPTIONS: Record<string, string> = {
  card: OptionsEnum.CARD,
  cash: OptionsEnum.CASH,
  transfer: OptionsEnum.TRANSFER,
  "transfer-subscriptions": OptionsEnum.TRANSFER_SUBSCRIPTIONS,
};

export const PAYOUTS_OPTIONS: Record<string, string> = {
  "payouts-card": OptionsEnum.PAYOUTS_CARD,
  "payouts-cash": OptionsEnum.PAYOUTS_CASH,
  "payouts-transfer": OptionsEnum.PAYOUTS_TRANSFER,
};

export const PRODUCT_OPTIONS: Record<string, string> = {
  subscriptions: OptionsEnum.SUBSCRIPTIONS,
  vpos: OptionsEnum.VPOS,
  webcheckout: OptionsEnum.WEBCHECKOUT,
};

export const OTHER_OPTIONS: Record<string, string> = {
  "card-chargeback": OptionsEnum.CARD_CHARGEBACK,
  "card-chargeback-void": OptionsEnum.CARD_CHARGEBACK_VOID,
  general: "URL General",
};

export const PAYIN_EVENTS: EventList = {
  card: {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
  cash: {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    cashPreathorization: TRANSACTION_STATUS.cashPreathorization,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
  transfer: {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
  "transfer-subscriptions": {
    approvedSubscription: TRANSACTION_STATUS.approvedSubscription,
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedSubscription: TRANSACTION_STATUS.declinedSubscription,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
};

export const PAYOUTS_EVENTS: EventList = {
  "payouts-card": {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
  "payouts-cash": {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
  "payouts-transfer": {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
};

export const PRODUCT_EVENTS: EventList = {
  subscriptions: {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
    failedRetry: TRANSACTION_STATUS.failedRetry,
    lastRetry: TRANSACTION_STATUS.lastRetry,
    subscriptionApproved: TRANSACTION_STATUS.subscriptionApproved,
    subscriptionDeclined: TRANSACTION_STATUS.subscriptionDeclined,
    subscriptionDelete: TRANSACTION_STATUS.subscriptionDelete,
  },
  vpos: {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
  webcheckout: {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
};

export const OTHER_EVENTS: EventList = {
  "card-chargeback": {
    initialized: TRANSACTION_STATUS.initialized,
    review: TRANSACTION_STATUS.review,
    // eslint-disable-next-line sort-keys
    pending: TRANSACTION_STATUS.pending,
    // eslint-disable-next-line sort-keys
    approval: TRANSACTION_STATUS.approval,
    declined: TRANSACTION_STATUS.declined,
    expired: TRANSACTION_STATUS.expired,
    failed: TRANSACTION_STATUS.failed,
  },
  "card-chargeback-void": {
    approvedTransaction: TRANSACTION_STATUS.approvedTransaction,
    declinedTransaction: TRANSACTION_STATUS.declinedTransaction,
  },
};

export const CARD_CHARGEBACK = {
  approval: "approval",
  declined: "declined",
  expired: "expired",
  failed: "failed",
  initialized: "initialized",
  pending: "pending",
  review: "review",
};

export const OPTIONS: Record<string, string> = {
  card: OptionsEnum.CARD,
  "card-chargeback": OptionsEnum.CARD_CHARGEBACK,
  "card-chargeback-void": OptionsEnum.CARD_CHARGEBACK_VOID,
  cash: OptionsEnum.CASH,
  general: "URL",
  "payouts-card": OptionsEnum.PAYOUTS_CARD,
  "payouts-cash": OptionsEnum.PAYOUTS_CASH,
  "payouts-transfer": OptionsEnum.PAYOUTS_TRANSFER,
  subscriptions: OptionsEnum.SUBSCRIPTIONS,
  transfer: OptionsEnum.TRANSFER,
  "transfer-subscriptions": OptionsEnum.TRANSFER_SUBSCRIPTIONS,
  vpos: OptionsEnum.VPOS,
  webcheckout: OptionsEnum.WEBCHECKOUT,
};
