import { IStyles } from "../../shared/interfaces/create_named_styles";

export const NotificationContainerStyles: IStyles = {
  searchText: {
    borderRadius: "15px",
    backgroundColor: "#FFFFFF",
    "& fieldset": {
      borderRadius: "15px !important",
    },
    width: "100%",
  },
};
