export const enum OptionsEnum {
  CARD = "Tarjeta",
  CASH = "Pagos en efectivo",
  PAYOUTS_CASH = "Dispersión de efectivo",
  TRANSFER_SUBSCRIPTIONS = "Débitos recurrentes",
  TRANSFER = "Transferencia",
  PAYOUTS_TRANSFER = "Dispersión de Transferencia",
  PAYOUTS_CARD = "Dispersión de Tarjeta",
  SUBSCRIPTIONS = "Suscripciones",
  WEBCHECKOUT = "Smartlinks",
  VPOS = "Virtual POS",
  CARD_CHARGEBACK_VOID = "Devoluciones",
  CARD_CHARGEBACK = "Contracargo",
}
