import React from "react";
import { SelectChangeEvent } from "@mui/material/Select";
import axios from "../shared/axios-util";
import { useEffect, useState } from "react";
import {
  IconCircleInformation,
  IconCircleWarn,
  useSnackbar,
} from "@kushki/connect-ui";
import { WebhookEventsEnum } from "../components/WebhookEventsCheckList/enums";
import { TRANSACTION_STATUS } from "../components/WebhookEventsCheckList/constants";
import { OptionsEnum } from "../shared/enums/OptionsEnum";
import { Webhook } from "../../types/post_webhooks_list_response";
import { API_ROUTES } from "../shared/constants/api_routes";
import { SnackBarColorEnum } from "../shared/enums/SnackBarColorEnum";
import { WebhookService } from "../shared/constants/enums/WebhookServiceEnum";

const enum DEFAULT_VALUES {
  CODE = "{}",
  EMPTY_STRING = "",
}

type BasicFormValue =
  | DEFAULT_VALUES.EMPTY_STRING
  | (WebhookEventsEnum | OptionsEnum);
type CodeEditor = DEFAULT_VALUES.CODE | string;

interface TransactionTypeField {
  label: string;
  value: string;
}

interface Card {
  url: string;
  status: "OK" | "FAILED";
  statusResponse: string;
}

interface TestConnection {
  card: Card[];
}

interface HookArgs {
  handleClose: () => void;
  webhook: Webhook;
}

const paymentMethodMappings = {
  [WebhookService.CARD]: OptionsEnum.CARD,
  [WebhookService.CASH]: OptionsEnum.CASH,
  [WebhookService.PAYOUTS_CARD]: OptionsEnum.PAYOUTS_CARD,
  [WebhookService.PAYOUTS_CASH]: OptionsEnum.PAYOUTS_CASH,
  [WebhookService.PAYOUTS_TRANSFER]: OptionsEnum.PAYOUTS_TRANSFER,
  [WebhookService.TRANSFER]: OptionsEnum.TRANSFER,
  [WebhookService.TRANSFER_SUBSCRIPTIONS]: OptionsEnum.TRANSFER_SUBSCRIPTIONS,
  [WebhookService.CARD_CHARGEBACK]: OptionsEnum.CARD_CHARGEBACK,
  [WebhookService.SUBSCRIPTIONS]: OptionsEnum.SUBSCRIPTIONS,
  [WebhookService.VPOS]: OptionsEnum.VPOS,
  [WebhookService.WEBCHECKOUT]: OptionsEnum.WEBCHECKOUT,
};

export const useWebhookSimulationModal = ({
  handleClose,
  webhook,
}: HookArgs) => {
  const [paymentMethodFields, setPaymentMethodFields] = useState<OptionsEnum[]>(
    []
  );

  const [transactionTypeFields, setTransactionTypeFields] = useState<
    TransactionTypeField[]
  >([
    {
      label: TRANSACTION_STATUS.approvedTransaction,
      value: WebhookEventsEnum.approvedTransaction,
    },
    {
      label: TRANSACTION_STATUS.declinedTransaction,
      value: WebhookEventsEnum.declinedTransaction,
    },
  ]);

  const [code, setCode] = useState<CodeEditor>(DEFAULT_VALUES.CODE);
  const [paymentMethod, setPaymentMethod] = useState<BasicFormValue>(
    DEFAULT_VALUES.EMPTY_STRING
  );
  const [transactionType, setTransactionType] = useState<BasicFormValue>(
    DEFAULT_VALUES.EMPTY_STRING
  );
  const [disableSimulateConnectionButton, setDisableSimulateConnectionButton] =
    useState(true);

  const [testConnectionResult, setTestConnectionResult] = useState<
    undefined | Card[]
  >();

  const [testConnectionLoading, setTestConnectionLoading] = useState(false);

  useEffect(() => {
    if (!webhook) return;
    if (!webhook.events) return;

    const paymentMethods = webhook.events.map((e) => e.service!);
    const uniquePaymentMethods = Array.from(new Set(paymentMethods));

    const mappedPaymentMethods = uniquePaymentMethods.map(
      (p) => paymentMethodMappings[p]
    );

    setPaymentMethodFields(mappedPaymentMethods);
  }, [webhook]);

  const getSecondFieldOptions = (paymentMethod: BasicFormValue) => {
    const declinedTransactionOption = {
      label: TRANSACTION_STATUS.declinedTransaction,
      value: WebhookEventsEnum.declinedTransaction,
    };

    if (paymentMethod === OptionsEnum.CARD_CHARGEBACK) {
      return [
        {
          label: TRANSACTION_STATUS.chargeback,
          value: WebhookEventsEnum.chargeback,
        },
        declinedTransactionOption,
      ];
    }

    return [declinedTransactionOption];
  };

  useEffect(() => {
    if (!webhook) return;
    if (!webhook.events) return;
    const [firstField] = transactionTypeFields;
    const secondField = getSecondFieldOptions(paymentMethod);
    const thirdField = {
      label: TRANSACTION_STATUS.cashPreathorization,
      value: WebhookEventsEnum.cashPreathorization,
    };

    const transactionFields =
      paymentMethod === OptionsEnum.CASH
        ? [firstField, ...secondField, thirdField]
        : [firstField, ...secondField];

    setTransactionTypeFields(transactionFields);
  }, [paymentMethod]);

  const handlePaymentMethodChange = (e: SelectChangeEvent<string>) =>
    setPaymentMethod(e.target.value as OptionsEnum);

  const handleCodeChange = (code: string) => setCode(() => code);
  const handleTransactionTypeChange = (e: React.BaseSyntheticEvent) =>
    setTransactionType(e.target.value as WebhookEventsEnum);

  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    const value =
      paymentMethod === DEFAULT_VALUES.EMPTY_STRING ||
      transactionType === DEFAULT_VALUES.EMPTY_STRING;

    setDisableSimulateConnectionButton(value);
  }, [paymentMethod, transactionType]);

  const resetState = () => {
    setPaymentMethod(DEFAULT_VALUES.EMPTY_STRING);
    setTransactionType(DEFAULT_VALUES.EMPTY_STRING);
    setCode(DEFAULT_VALUES.CODE);
  };

  const closeModal = () => {
    resetState();
    handleClose();
  };

  const handleSubmit = async () => {
    if (disableSimulateConnectionButton) return;
    if (!webhook || !webhook.status || !webhook.events) return;

    setTestConnectionLoading(true);
    const urls = webhook.status.map((s) => s.url);

    try {
      const { data } = await axios.post<TestConnection>(
        API_ROUTES.TEST_CONNECTION,
        {
          webhookData: [
            {
              body: code,
              kindTransaction: transactionType,
              service: paymentMethod,
              urls,
            },
          ],
        }
      );

      showSnackbar({
        color: SnackBarColorEnum.INFO,
        icon: <IconCircleInformation />,
        message: "Se ha simulado la conexión con éxito.",
        variant: "simple",
        withIcon: true,
      });
      setTestConnectionResult(data.card);
    } catch {
      showSnackbar({
        color: SnackBarColorEnum.DANGER,
        icon: <IconCircleWarn />,
        message:
          "La simulación de la conexión ha fallado, inténtalo nuevamente.",
        variant: "simple",
        withIcon: true,
      });
    } finally {
      setTestConnectionLoading(false);
      closeModal();
    }
  };

  return {
    disableSimulateConnectionButton,
    editor: {
      handleChange: handleCodeChange,
      value: code,
    },
    handleSubmit,
    paymentMethod: {
      fields: paymentMethodFields,
      handleChange: handlePaymentMethodChange,
      value: paymentMethod,
    },
    testConnectionResult: {
      data: testConnectionResult,
      loading: testConnectionLoading,
    },
    transactionType: {
      fields: transactionTypeFields,
      handleChange: handleTransactionTypeChange,
      value: transactionType,
    },
  };
};
