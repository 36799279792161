import React, { FC } from "react";
import { useNotificationsContainer } from "./state/useNotificationsContainer";
import { FilterBar, ModalLoader, TableSimple, Title } from "@kushki/connect-ui";
import { Box, Grid } from "@mui/material";
import { END_PICKER, START_PICKER } from "../../shared/constants/date_values";
import SearchText from "../../components/SearchText/SearchText";

const NotificationsContainer: FC = () => {
  const {
    rows,
    columns,
    isLoaderModalOpen,
    textFieldSearchBy,
    isInitialLoading,
    isLoading,
    totalItems,
    selectedPage,
    handlePageClick,
    mockFn,
    getDateRangePicker,
    changeItemsPerPage,
    itemsPerPage,
    handleApplyFilter,
    filterPaymentMethods,
    filterStatusOptions,
    buildBreadCrumbItems,
    trxIdentifierFilter,
  } = useNotificationsContainer();

  return (
    <Box sx={{ mx: "50px" }}>
      <Box sx={{ mb: "56px", mt: "72px" }}>
        <Title
          items={buildBreadCrumbItems()}
          onButtonClick={mockFn}
          onButtonClickSecondary={mockFn}
          title="Notificaciones de webhooks"
          type="titleBase"
        />
      </Box>
      {rows && (
        <>
          <Grid container>
            <Grid item sm={12} md={4} pr={1} pb={1}>
              <SearchText
                placeholder={
                  "Buscar por número de ticket o referencia de transacción"
                }
                trxIdentifierFilter={trxIdentifierFilter}
              />
            </Grid>
            <Grid item sm={12} md={8}>
              <FilterBar
                filterSideBar={{
                  categoryItems: [
                    {
                      isMinimize: true,
                      items: filterPaymentMethods,
                      onExpand: mockFn,
                      onItemsSelect: mockFn,
                      placeHolder: "Medio de pago o producto",
                      selectType: "multiple",
                      type: "byChips",
                    },
                    {
                      isMinimize: true,
                      items: filterStatusOptions,
                      onExpand: mockFn,
                      onItemsSelect: mockFn,
                      placeHolder: "Estado de conexión",
                      selectType: "single",
                      type: "byChips",
                    },
                  ],
                  filtersCount: 0,
                  isMinimized: false,
                  isOpen: true,
                  onClick: handleApplyFilter,
                  title: "Filtrar por",
                }}
                rangeDatePicker={{
                  dateFormat: "dd/MMM/yyyy",
                  defaultFinishDate: START_PICKER,
                  defaultStartDate: END_PICKER,
                  disabledFutureDate: false,
                  disabledOldDate: false,
                  isFilled: false,
                  isOpen: false,
                  onDateSelect: getDateRangePicker,
                  placeholder: "Buscar por Fecha",
                }}
                textFieldSearchBy={textFieldSearchBy}
              />
            </Grid>
          </Grid>

          <TableSimple
            data-testid="NotificationsContainerTableSimple"
            footerPagination={{
              isDisable: false,
              itemsPerPage: itemsPerPage,
              onItemsPerPageChange: changeItemsPerPage,
              onPageClick: handlePageClick,
              paginationText: "Notificaciones por página",
              selectedPage,
              textFieldSelector: [5, 10, 25, 50, 75, 100],
              totalItems,
            }}
            header={{
              cells: columns,
              headerProps: {
                isDisable: false,
              },
            }}
            rows={rows}
            showPagination
            isLoading={isLoading}
            isInitialLoading={isInitialLoading}
          />
        </>
      )}
      <ModalLoader
        descriptionText="Este proceso puede tardar unos minutos..."
        titleText="Estamos reenviando la notificación"
        isOpen={isLoaderModalOpen}
      />
    </Box>
  );
};

export default NotificationsContainer;
