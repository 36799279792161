import {
  IUseSearchText,
  IUseSearchTextState,
} from "./useSearchTextState.interfaces";
import { PagesEnum } from "../../../shared/enums/PagesEnum";

export const useSearchTextState = (
  props: IUseSearchText
): IUseSearchTextState => {
  const handleOnSearch = () => {
    props.trxIdentifierFilter.setSelectedPage(PagesEnum.FIRST);
    props.trxIdentifierFilter.handleSearchTrxIdentifier();
  };

  const handleKeyPress = (e: React.KeyboardEvent): void => {
    if (e.key === "Enter") {
      handleOnSearch();
    }
  };

  const handleSearchChange = (
    e: React.ChangeEvent<{ value: string }>
  ): void => {
    const text_to_search: string = e.target.value;

    props.trxIdentifierFilter.setTrxIdentifier(text_to_search);
  };

  return {
    handleKeyPress,
    handleSearchChange,
  };
};
