export enum WebhookServiceEnum {
  GENERAL = "general",
}

export enum WebhookStatus {
  FAILED = "FAILED",
  OK = "200",
  EMPTY = "",
}

export enum WebhookService {
  CARD = "card",
  CASH = "cash",
  PAYOUTS_CASH = "payouts-cash",
  TRANSFER_SUBSCRIPTIONS = "transfer-subscriptions",
  TRANSFER = "transfer",
  PAYOUTS_TRANSFER = "payouts-transfer",
  PAYOUTS_CARD = "payouts-card",
  SUBSCRIPTIONS = "subscriptions",
  WEBCHECKOUT = "webcheckout",
  VPOS = "vpos",
  CARD_CHARGEBACK = "card-chargeback",
  CARD_CHARGEBACK_VOID = "card-chargeback-void",
}
