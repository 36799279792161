import { WEBHOOK_SERVICE_TEXT } from "./webhooks_container";
import { WebhookService, WebhookStatus } from "./enums/WebhookServiceEnum";

export const OPTIONS_FILTER_PAYMENT_METHOD = [
  {
    label: WEBHOOK_SERVICE_TEXT[WebhookService.CARD],
    selected: false,
    value: WebhookService.CARD,
  },
  {
    label: WEBHOOK_SERVICE_TEXT[WebhookService.CASH],
    selected: false,
    value: WebhookService.CASH,
  },
  {
    label: WEBHOOK_SERVICE_TEXT[WebhookService.TRANSFER],
    selected: false,
    value: WebhookService.TRANSFER,
  },
  {
    label: WEBHOOK_SERVICE_TEXT[WebhookService.WEBCHECKOUT],
    selected: false,
    value: WebhookService.WEBCHECKOUT,
  },
  {
    label: WEBHOOK_SERVICE_TEXT[WebhookService.CARD_CHARGEBACK],
    selected: false,
    value: WebhookService.CARD_CHARGEBACK,
  },
  {
    label: WEBHOOK_SERVICE_TEXT[WebhookService.CARD_CHARGEBACK_VOID],
    selected: false,
    value: WebhookService.CARD_CHARGEBACK_VOID,
  },
  {
    label: WEBHOOK_SERVICE_TEXT[WebhookService.TRANSFER_SUBSCRIPTIONS],
    selected: false,
    value: WebhookService.TRANSFER_SUBSCRIPTIONS,
  },
  {
    label: WEBHOOK_SERVICE_TEXT[WebhookService.PAYOUTS_TRANSFER],
    selected: false,
    value: WebhookService.PAYOUTS_TRANSFER,
  },
  {
    label: WEBHOOK_SERVICE_TEXT[WebhookService.VPOS],
    selected: false,
    value: WebhookService.VPOS,
  },
  {
    label: WEBHOOK_SERVICE_TEXT[WebhookService.PAYOUTS_CASH],
    selected: false,
    value: WebhookService.PAYOUTS_CASH,
  },
  {
    label: WEBHOOK_SERVICE_TEXT[WebhookService.SUBSCRIPTIONS],
    selected: false,
    value: WebhookService.SUBSCRIPTIONS,
  },
  {
    label: WEBHOOK_SERVICE_TEXT[WebhookService.PAYOUTS_CARD],
    selected: false,
    value: WebhookService.PAYOUTS_CARD,
  },
];

export const OPTIONS_FILTER_STATUS = [
  {
    label: "Exitoso",
    selected: false,
    value: WebhookStatus.OK,
  },
  {
    label: "Fallido",
    selected: false,
    value: WebhookStatus.FAILED,
  },
];
